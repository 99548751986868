import React from "react";
import { Link } from "react-router-dom";
import Projects from "../../api/projects";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handlemail = () => {
    const mailto =
      "mailto:iserenify@gmail.com?subject=iSerenify - Informatii&body= ";
    window.location.href = mailto;
  };

  const handletel = () => {
    const telto = "tel:+40748958596";
    window.location.href = telto;
  };

  const handleWhatsApp = () => {
    const whatsappUrl = "https://wa.me/40748958596";
    window.location.href = whatsappUrl;
  };

  return (
    <footer className="wpo-site-footer">
      <div className="footer-wave">
        <svg
          viewBox="0 0 1440 320"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#848dc7" /* Change this color to match your design */
            fillOpacity="1"
            d="M0,160L48,165.3C96,171,192,181,288,176C384,171,480,149,576,144C672,139,768,149,864,144C960,139,1056,117,1152,122.7C1248,128,1344,160,1392,176L1440,192V320H0Z"
          ></path>
        </svg>
      </div>

      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            {/* <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12"> */}
            <div className="widget about-widget">
              <div className="logo widget-title">
                <Link onClick={ClickHandler} className="logo" to="/">
                  <img src="../iserenify_logo_white.png" />
                </Link>
              </div>
              {/* <p>Make your dreams come true.</p> */}

              {/* <ul>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-facebook"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-twitter-alt"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-instagram"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-google"></i>
                                    </Link>
                                </li>
                            </ul> */}
            </div>
            {/* </div> */}
            {/* <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Information</h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                <li><Link onClick={ClickHandler} to="/portfolio">Portfolio</Link></li>
                                <li><Link onClick={ClickHandler} to="/blog">Latest News</Link></li>
                                <li><Link onClick={ClickHandler} to="/contact">Contact us</Link></li>
                                <li><Link onClick={ClickHandler} to="/service">Our Services</Link></li>
                            </ul>
                        </div>
                    </div> */}
            {/* <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12"> */}
            {/* <div className="widget wpo-service-link-widget"> */}
            {/* <div className="widget-title">
                  <h3>Contact </h3>
                </div> */}
            {/* <div className="contact-ft"> */}
            <p>
              Would you have any enquiries. Please feel free to contuct us
            </p>
            {/* <ul>
                    <li>Ariana Henzel</li>
                    <li onClick={handlemail} className="custom-email-icon">
                      <i className="fi flaticon-email"></i>
                      iserenify@gmail.com
                    </li>
                    <li onClick={handletel} className="custom-tel-icon">
                      <i className="fi flaticon-phone-call"></i>+40 748 958 596
                    </li>
                    <li>
                      <i className="fi flaticon-maps-and-flags"></i>Sibiu,
                      Romania
                    </li>
                  </ul> */}

            {/* </div> */}

            {/* </div> */}

            {/* </div> */}
            <div className="footer">
              <section className={`social-section-footer section-padding `}>
                <div className="social-wrap-footer">
                  <div className="social-item-footer">
                    <div className="social-text-footer">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/ariana.henzel1"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fi flaticon-facebook-app-symbol"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/ariana.henzel?igsh=MTN3MDJ0dTJ1am16Mg=="
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fi flaticon-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a onClick={handleWhatsApp}>
                            <i className="fi flaticon-new-whatsapp"></i>
                          </a>
                        </li>
                        <li>
                          <a onClick={handlemail}
                          >
                            <i className="fi flaticon-email"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget instagram">
                            <div className="widget-title">
                                <h3>Instagram</h3>
                            </div>
                            <ul className="d-flex">
                                {Projects.slice(0,6).map((project, pitem) => (
                                    <li key={pitem}><Link onClick={ClickHandler} to={`/project-single/${project.id}`}><img src={project.pimg1} alt=""/></Link></li>
                                ))}
                            </ul>
                        </div>
                    </div> */}

            <div style={{ textAlign: "center" }}>
              <Link onClick={ClickHandler} to="/home">
                <img
                  src="../iserenify_logo_white_drop.png"
                  alt="logo"
                  style={{ width: 100 }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright">
                {" "}
                &copy; 2024 iSerenify. Design By{" "}
                <Link onClick={ClickHandler} to="/">
                  Claudiu Popa
                </Link>
                . All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
