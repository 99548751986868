import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

class ContactForm extends Component {
  state = {
    name: "",
    email: "",
    service: "",
    lastname: "",
    notes: "",
    error: {},
  };
  c;
  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };
  postRequest = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE}/contact/post`,
        {
          name: this.state.name,
          email: this.state.email,
          service: this.state.service,
          notes: this.state.notes,
          lastname: this.state.lastname,
        },
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((response) => {
        toast.success("Thank you for your message");
        this.setState({
          name: "",
          lastname: "",
          email: "",
          service: "",
          notes: "",
          error: {},
        });
      })
      .catch((error) => {
        console.log(error);
        toast.warning("Message not sent");
      });
  };

  subimtHandler = (e) => {
    e.preventDefault();
    const { name, email, service, lastname, notes, error } = this.state;

    if (name === "") {
      error.name = "Please enter your name";
    }
    if (email === "") {
      error.email = "Please enter your email";
    }
    if (service === "") {
      error.service = "Please enter your service";
    }
    if (lastname === "") {
      error.lastname = "Please enter your Lastname";
    }
    if (notes === "") {
      error.notes = "Please enter your note";
    }

    if (error) {
      this.setState({
        error,
      });
    }
    if (
      error.name === "" &&
      error.email === "" &&
      error.email === "" &&
      error.lastname === "" &&
      error.service === "" &&
      error.notes === ""
    ) {
      this.postRequest();
    }
  };

  render() {
    const { name, email, service, lastname, error, notes } = this.state;
    const { user } = this.props.user;
    return (
      <form onSubmit={this.subimtHandler} className="form">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input
                value={name}
                onChange={this.changeHandler}
                type="text"
                name="name"
                placeholder="Name"
              />
              <p>{error.name ? error.name : ""}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input
                value={lastname}
                onChange={this.changeHandler}
                type="text"
                name="lastname"
                placeholder="Lastname"
              />
              <p>{error.lastname ? error.lastname : ""}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input
                onChange={this.changeHandler}
                value={email}
                type="email"
                name="email"
                placeholder="Email"
              />
              <p>{error.email ? error.email : ""}</p>
            </div>
          </div>
          {/* <div className="col-lg-6 col-md-6 col-12">
            <div>
              <select
                name="service"
                className="form-control"
                value={service}
                onChange={this.changeHandler}
              >
                <option>Service</option>
                <option>Package ZEN</option>
                <option>Package ARMONIA</option>
                <option>Package BASIC</option>
                <option>Package ARISA</option>
                <option>Package CORPORATE</option>
                <option>Package SPECIAL</option>
                <option>Others</option>
              </select>
              <p>{error.service ? error.service : ""}</p>
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="form-field">
              <textarea
                name="notes"
                placeholder="Message"
                onChange={this.changeHandler}
                value={notes}
              ></textarea>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-submit">
              <button type="submit" className="theme-btn-s4 mt-4">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(ContactForm);
