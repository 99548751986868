import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link, withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  auth,
  provider_fb,
  provider_google,
  provider_apple,
} from "../Firebase";
import { addUser } from "../../store/actions/action";

import "./style.scss";

const LoginPage = (props) => {
  let history = useHistory();

  const [value, setValue] = useState({
    email: "user@gmail.com",
    password: "123456",
    remember: false,
  });

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };

  const rememberHandler = () => {
    setValue({ ...value, remember: !value.remember });
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const handle_facebook_login = (e) => {
    e.preventDefault();
    auth
      .signInWithPopup(provider_fb)
      .then((result) => {
        props.addUser(result.additionalUserInfo.profile);
        history.push("/");
      })
      .catch((error) => toast.error(error.message));
  };

  const handle_google_login = (e) => {
    e.preventDefault();

    auth
      .signInWithPopup(provider_google)
      .then((result) => {
        props.addUser(result.user);
        history.push("/home");
      })
      .catch((error) => toast.error(error.message));
  };

  const handle_apple_login = (e) => {
    e.preventDefault();
    auth
      .signInWithPopup(provider_apple)
      .then((result) => {
        // console.log(result);
        // dispatch({
        //     type: actionTypes.SET_USER,
        //     user: result.user,
        // });
        toast.success("You successfully Login on iSerenify!");
        history.push("/home");
      })
      .catch((error) => toast.error(error.message));
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      setValue({
        email: "",
        password: "",
        remember: false,
      });
      validator.hideMessages();

      const email = value.email;
      auth
        .signInWithEmailAndPassword(email, value.password)
        .then((auth) => {
          props.addUser(auth.user.multiFactor.user);
          history.push("/");
        })
        .catch((e) => toast.error(e.message));
    } else {
      validator.showMessages();
    }
  };

  // useEffect(() => {
  //   console.log(props.user);
  // }, [props.user]); // Only re-subscribe if props.friend.id changes

  return (
    <Grid className="loginWrapper">
      <Grid className="loginForm">
        <h2>Sign In</h2>
        <p>Sign in to your account</p>
        <form onSubmit={submitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="E-mail"
                value={value.email}
                variant="outlined"
                name="email"
                label="E-mail"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("email", value.email, "required|email")}
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="Password"
                value={value.password}
                variant="outlined"
                name="password"
                type="password"
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("password", value.password, "required")}
            </Grid>
            <Grid item xs={12}>
              <Grid className="formAction">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value.remember}
                      onChange={rememberHandler}
                    />
                  }
                  label="Remember Me"
                />
                {/* <Link to="/forgot-password">Forgot Password?</Link> */}
              </Grid>
              <Grid className="formFooter">
                <Button fullWidth className="cBtnTheme" type="submit">
                  Login
                </Button>
              </Grid>
              <Grid className="loginWithSocial">
                <Button className="facebook" onClick={handle_facebook_login}>
                  <i className="fa fa-facebook"></i>
                </Button>
                <Button className="google" onClick={handle_google_login}>
                  <i className="fa fa-google"></i>
                </Button>
                {/* <Button className="linkedin" onClick={handle_apple_login}>
                  <i className="fa fa-apple"></i>
                </Button> */}
              </Grid>
              <p className="noteHelp">
                Don't have an account?{" "}
                <Link to="/register">Create free account</Link>
              </p>
            </Grid>
          </Grid>
        </form>
        <div className="shape-img">
          <i className="fi flaticon-honeycomb"></i>
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    loggedIn: state.user.loggedIn,
  };
};
export default connect(mapStateToProps, { addUser })(LoginPage);
