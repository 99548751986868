import React from "react";
import arianaImage from "../../images/iserenify/iserenify_ariana_image_circle.JPEG";

const About = (props) => {
  return (
    <section className={`wpo-about-section section-padding ${props.pt}`}>
      <div className="container">
        <div className="wpo-about-section-wrapper">
          <div className="row align-items-center">
            {/* <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img">
                <img src={arianaImage} alt="" />
              </div>
            </div> */}
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-content">
                <div className="about-title">
                  <h2>Povestea mea</h2>
                </div>
                <div className="wpo-about-content-inner">
                  <section>
                    <p>
                      Îți mulțumesc că alegi să îmi citești povestea.
                    </p>

                    <p>
                      Am ales drumul uleiurilor esențiale în Noiembrie 2022. De
                      ce? Ei bine, povestea e lungă, dar ca să o scurtez, timp
                      de doi ani m-am confruntat cu anxietate și atacuri de
                      panică la care ulterior s-au mai adăugat depresia și
                      insomnia. Doi ani în care am încercat tot ce se poate
                      încerca: calmante, somnifere, sedative, ceaiuri, masaje,
                      chiar și homeopate.
                    </p>

                    <p>
                      Doi ani în care m-am distanțat de oamenii dragi mie pentru
                      că nu mai eram om, eram o legumă care dormea 17 ore pe zi
                      și mă trezea atacul de panică, ajungând să nu mai pot
                      dormi deloc două zile consecutive. Eram în modul de
                      supraviețuire și cam atât. Dacă mergeam la cumpărături, nu
                      se putea să nu fiu întâmpinată de atacul de panică și,
                      fiindcă știam că mă așteaptă mereu, am ales să mă izolez
                      de tot și toate.
                    </p>

                    <p>
                      Simțeam că sunt un om bolnav, deși analizele îmi erau bune
                      și toți medicii pe la care am umblat mi-au confirmat acest
                      lucru. Dar eu mă simțeam mai bolnavă decât se simte un om
                      bolnav.
                    </p>

                    <p>
                      În Noiembrie 2022, am ales să încerc și uleiurile
                      esențiale. Deși mereu spuneam că nu au cum să mă ajute
                      niște uleiuri dacă nu m-a ajutat un protocol de medicație
                      pentru anxietate și îi spuneam mamei că eu vreau să mă fac
                      bine, nu să miros frumos… hehe…
                    </p>

                    <p>
                      Surpriza a fost că după o săptămână de utilizare, am
                      început să ies iar din casă, am început să dorm mai bine
                      și să îmi fac planuri de viitor. Iar după o lună, eram ca
                      nouă.
                    </p>
                  </section>

                  <section>
                    <h2>Ce am făcut?</h2>
                    <p>
                      Lavandă și tămâie din greu și multe documentări despre
                      conflictele biologice, conștientizări ale blocajelor
                      emoționale care mă țineau într-un cerc vicios de
                      panică-insomnie-depresie. Unii îi spun noroc, alții
                      placebo, eu îi spun <strong>CUNOAȘTERE</strong>.
                    </p>

                    <p>
                      Nu degeaba se spune că cunoașterea este putere. Am învățat
                      să îmi ascult trupul, mintea și sufletul.
                    </p>

                    <p>
                      Ulterior, din atât de mult entuziasm, am început să
                      împărtășesc povestea mea cu oricine aveam ocazia, și
                      atunci am simțit că pot ajuta mai multe suflete în acest
                      fel. Având o facultate și un master în psihologie, pot
                      spune că știam să ascult foarte bine, dar abia acum am
                      simțit că pot să și înțeleg și să ajut.
                    </p>
                    <div className="signature">
                      Ariana Henzel
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ paddingTop: '20px' }}>
        <div className="wpo-about-section-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-content">
                <div className="wpo-about-content-inner">
                  {/* <section>
                    <h2>Vino să ne cunoaștem!</h2>
                  </section>
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="wpo-about-img">
                      <img src={arianaImage} alt="" className="styled-image" draggable="false" />
                    </div>
                  </div> */}

                  <section>
                  <p>
                    <h2>Vino să ne cunoaștem!</h2>
                  </p>
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="wpo-about-img">
                      <img src={arianaImage} alt="" className="styled-image" draggable="false" />
                    </div>
                  </div>
                    <p>
                      Alătură-te unei experiențe senzoriale și olfactive de
                      neuitat. Descoperă beneficiile uleiurilor esențiale și
                      simte-le puterea în mâna ta.
                    </p>

                    <p>
                      Programează o ședință de consultanță ca să le poți testa{" "}
                      <strong>Gratuit!</strong> Online sau fizic.
                    </p>

                    <p>
                      <strong>
                        Vrei un pachet de mostre personalizat GRATUIT?
                      </strong>
                      <br></br>
                      Rezervă-ți unul chiar acum.{" "}
                      <em>În limita stocului disponibil</em>.
                    </p>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
